import React from 'react'
import Layout from '../components/layout'
import BackgroundImage from 'gatsby-background-image'
// import { TeamViewer } from './../components/teamviewer'
import Particles from 'react-particles-js'
import { graphql } from 'gatsby'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import SEO from '../components/seo'

class MySite extends React.Component {
  render() {
    return (
      <Layout>
        <SEO />
        <main>
          <div id="hero">
            <BackgroundImage fluid={this.props.data.hero.childImageSharp.fluid}>
              <Container>
                <Row>
                  <Col sm={12} lg={6}>
                    <div class="hero-overlay">
                      <h1 style={{ marginBottom: '2rem' }}>
                        GN Informatica di{' '}
                        <span className="d-block accent">Giuseppe Nespoli</span>
                      </h1>
                      <h2>
                        Servizi informatici{' '}
                        <span className="d-block accent">aziendali</span>
                      </h2>
                    </div>
                  </Col>
                </Row>
              </Container>
            </BackgroundImage>
          </div>
          <section id="about">
            <Container>
              <div className="text-center">
                <h2>
                  Hai bisogno di <span className="accent">assistenza?</span>
                </h2>
                <h5 style={{ marginBottom: '2rem' }}>
                  Siamo pronti a soddisfare qualsiasi tipo di esigenza
                </h5>
              </div>
              <Row className="pb-2 justify-content-center">
                <Col className="text-center pb-4">
                  <FontAwesomeIcon
                    className="my-3"
                    size="4x"
                    icon="briefcase"
                  />
                  <br />
                  <Button href="/servizi" variant="warning">
                    I nostri servizi
                  </Button>
                </Col>
                <Col className="text-center pb-4">
                  <FontAwesomeIcon className="my-3" size="4x" icon="phone" />
                  <br />
                  <Button href="/contattaci" variant="warning">
                    Contattaci
                  </Button>
                </Col>
                <Col className="text-center pb-4">
                  <FontAwesomeIcon className="my-3" size="4x" icon="headset" />
                  <br />
                  <Button
                    href="https://get.teamviewer.com/gninformatica"
                    variant="warning"
                  >
                    Assistenza remota
                  </Button>
                </Col>
              </Row>
            </Container>
          </section>

          <div className="divider">
            <ThemeToggler>
              {({ theme }) => (
                <Particles
                  style={{ maxHeight: '10rem' }}
                  params={{
                    particles: {
                      number: {
                        value: 100,
                        density: {
                          enable: true,
                          value_area: 500,
                        },
                      },
                      color: {
                        value: theme === 'dark' ? '#fff' : '#000',
                      },
                      line_linked: {
                        color: theme === 'dark' ? '#fff' : '#000',
                      },
                      move: {
                        enable: true,
                        speed: 3,
                        direction: 'none',
                        random: true,
                        straight: false,
                        out_mode: 'out',
                        bounce: false,
                      },
                    },
                    interactivity: {
                      detect_on: 'windows',
                      events: {
                        onhover: {
                          enable: true,
                          mode: 'grab',
                        },
                      },
                    },
                  }}
                />
              )}
            </ThemeToggler>
          </div>

          <section id="services">
            <Container>
              <div className="text-center">
                <h2 style={{ marginBottom: '2rem' }}>
                  La nostra <span className="accent">offerta</span>
                </h2>
              </div>
              <Row>
                <Col sm className="text-center">
                  <FontAwesomeIcon className="my-3" size="4x" icon="cloud" />
                  <br />
                  Cloud
                </Col>
                <Col sm className="text-center">
                  <FontAwesomeIcon className="my-3" size="4x" icon="tools" />
                  <br />
                  Supporto tecnico
                </Col>
                <Col sm className="text-center">
                  <FontAwesomeIcon
                    className="my-3"
                    size="4x"
                    icon="network-wired"
                  />
                  <br />
                  Networking
                </Col>
                <Col sm className="text-center">
                  <FontAwesomeIcon
                    className="my-3"
                    size="4x"
                    icon="share-alt"
                  />
                  <br />
                  Collaborazione
                </Col>
                <Col sm className="text-center">
                  <FontAwesomeIcon
                    className="my-3"
                    size="4x"
                    icon="expand-arrows-alt"
                  />
                  <br />
                  ...e molto altro!
                </Col>
              </Row>
            </Container>
          </section>
        </main>
      </Layout>
    )
  }
}
export default MySite

export const heroImage = graphql`
  fragment heroImage on File {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "hero.jpg" }) {
      ...heroImage
    }
  }
`
